import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import news1 from "../../assets/homepage/news1.png";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getArticles } from "../../services/api";
import LoadingComp from "../Loading/LoadingComp";
import htmlTruncate from "html-truncate";

import moment from 'moment';

export default function Articles() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const handleArticleAll = () => {
    navigate("/articles");
  };
  useEffect(() => {
    Aos.init();
  }, []);
  const [articles, setArticles] = useState([]);

  async function getArticle() {
    try {
      const responseArticles = await getArticles().then(
        (result) => result.data.data.content
      );
      setArticles(responseArticles);
      setTimeout(() => {
        setLoading(false);
      }, 5000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    getArticle();
  }, []);

  let sliderArticles = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    autoplay: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    prevArrow: (
      <button>
        <svg
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.38469 15.1337L9.51844 7.99996L2.38469 0.866211L0.617188 2.63371L5.98344 7.99996L0.617188 13.3662L2.38469 15.1337Z"
            fill="#B89848"
          />
        </svg>
      </button>
    ),
    nextArrow: (
      <button>
        <svg
          width="10"
          height="16"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.38469 15.1337L9.51844 7.99996L2.38469 0.866211L0.617188 2.63371L5.98344 7.99996L0.617188 13.3662L2.38469 15.1337Z"
            fill="#B89848"
          />
        </svg>
      </button>
    ),
    responsive: [
      {
        breakpoint: 1201,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
          centerPadding: "30px",
        },
      },
    ],
  };
  const TruncatedHTML = ({ htmlString, maxLength }) => {
    const truncatedHTML = htmlTruncate(htmlString, maxLength);
    return <div dangerouslySetInnerHTML={{ __html: truncatedHTML }} />;
  };
  return (
    <div
      className="box-articles d-flex justify-content-center articles"
      id="articles"
    >
      <div className="d-flex justify-content-center width-100">
        <div className="contant-article d-flex align-items-start width-100">
          <div className="d-flex flex-column text-start article-short"
            data-aos="fade-up-right"
            data-aos-duration="1500"
          >
            <h4 className="c-00 mb-40">ARTICLES</h4>
            <p className="description c-00 DMSans-400">
              Lorem ipsum dolor sit amet consectetur. Sapien vulputate eu
              pharetra quis sit urna. Amet pellentesque id at a ut enim aliquet
              nisl. Nulla facilisis elit suspendisse nisi tortor eu.
            </p>
            
            <p className="box-link-view">
              <span
                className="c-89 pointer DMSans-700 no"
                onClick={handleArticleAll}
              >
                VIEW ALL ARTICLES {">"}
              </span>
            </p>
          </div>
          <div className="article-list"
            data-aos="fade-up-left"
            data-aos-duration="1500"
          >
            {loading ? (
              <LoadingComp statusLoading={setLoading} color="#b89848" />
            ) : (
              <div className="width-100">
                <Slider {...sliderArticles}>
                  {articles.map((item, i) => (
                    <div className="card-article width-100" key={i}>
                      <div className="shd-40 width-100">
                        <div className="news-box width-100">
                          <img
                            src={news1}
                            className="img-fluid img-cover width-100"
                            alt="img-article"
                          />
                          <p className="DMSans-700 c-00">News</p>
                        </div>
                        <div className="text-box d-flex flex-column text-start justify-content-between width-100">
                          <div className="d-flex flex-column text-start">
                            <p className="DMSans-400 date c-52 s-1621">
                              {moment().format('DD MMMM YYYY',item.date)}
                            </p>
                            <h3 className="DMSans-700 c-00 s-2026">
                              <TruncatedHTML
                                className="DMSans-700 c-00 s-2026"
                                htmlString={item.title}
                                maxLength={45}
                              />
                            </h3>
                            <p className="description DMSans-400 c-00">
                              <TruncatedHTML
                                htmlString={item.summary}
                              maxLength={120}
                            />
                            </p>
                            
                          </div>
                          <Link
                            to={item.link}
                            target="_blank"
                            className="c-89 s-1823 DMSans-700 no"
                          >
                            {" "}
                            Find out more {">"}
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
