import { React, useState, useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import banner from "../../assets/erp/banner.png";
import { getProduct } from "../../services/api";
import ScrollDown from "../ScrollDown";
import LoadingComp from "../Loading/LoadingComp";

export default function Banner() {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState([]);
  async function getProductData() {
    try {
      const responseProduct = await getProduct().then(
        (result) => result.data.data
      );
      setProduct(responseProduct);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    Aos.init();
    getProductData();
  }, []);
  return (
    <div>
      <div className="banner-erp d-flex align-items-center mb-200 width-100">
        <img
          src={banner}
          className="banner-img width-100"
          alt="img-banner"
          data-aos="zoom-in"
          data-aos-duration="1500"
        />
        <ScrollDown />
        <div className="banner-content width-100 d-flex justify-content-center relative">
          {loading ? (
            <LoadingComp statusLoading={setLoading} color="#fff" />
          ) : (
            <>
              {product.map((productView, index) => {
                return (
                  index === 0 && (
                    <div className="box-inner" key={index}>
                      <div className="box-content-inner d-flex align-items-center width-100">
                        <div className="erp-text">
                          <div
                            className="content-text text-start width-100"
                            data-aos="fade-up"
                            data-aos-duration="1500"
                          >
                            <h1 className="s-6483 Arlon c-ff mb-30">
                              {productView.title}
                            </h1>
                            {/* <h1 className="s-6483 Arlon c-ff mb-30">ERP</h1> */}
                            <p className="DMSans-400 c-ff s-2431">
                              {productView.shortDescription}
                            </p>
                          </div>
                        </div>
                        <div
                          className="erp-img relative"
                          data-aos="fade-up-left"
                          data-aos-duration="1500"
                        >
                          <div
                            className="content-img d-flex justify-content-between"
                            data-aos="fade-up-left"
                            data-aos-duration="1500"
                          >
                            <div className="box-img-left">
                              <img
                                src={productView.image[0]}
                                className="banner-left img-cover width-100 img-fluid"
                                alt="img-content"
                              />
                            </div>
                            <div className="box-img-right">
                              <img
                                src={productView.image[1]}
                                alt=""
                                className="banner-right img-cover"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                );
              })}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
