import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getTestimonies, getClient, getClientSlider } from "../../services/api";
import LoadingComp from "../Loading/LoadingComp";
import htmlTruncate from "html-truncate";

export default function OurClients() {
  const [testimonies, setTestimonies] = useState([]);
  const [clients, setClients] = useState([]);
  const [clientSlider, setClientSider] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getTestimoniesData() {
    try {
      const responseTestimonies = await getTestimonies().then(
        (result) => result.data.data
      );
      setTestimonies(responseTestimonies);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  async function getClienstData() {
    try {
      const responseClients = await getClient().then(
        (result) => result.data.data
      );
      setClients(responseClients);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  async function getClienstSliderData() {
    try {
      const responseSlider = await getClientSlider().then(
        (result) => result.data.data
      );
      setClientSider(responseSlider);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    Aos.init();
    getTestimoniesData();
    getClienstData();
    getClienstSliderData();
  }, []);

  let clientBannerStudy = {
    arrows: false,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    vertical: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          speed: 1000,
        },
      },
    ],
  };

  let clientList = {
    dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    centerMode: false,
    autoplaySpeed: 0,
    speed: 4000,
    pauseOnHover: false,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  let clientSays = {
    dots: true,
    arrows: true,
    infinite: true,
    centerMode: true,
    centerPadding: "0px",
    autoplay: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 1,
    // prevArrow: <button><img src={left} alt='left-arrow' className='img-fluid' /></button> ,
    // nextArrow: <img src={right} alt='right-arrow' className='img-fluid'/>,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "40px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          dots: false,
          arrows: false,
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "35px",
        },
      },
    ],
  };
  const TruncatedHTML = ({ htmlString, maxLength }) => {
    const truncatedHTML = htmlTruncate(htmlString, maxLength);
    return <div dangerouslySetInnerHTML={{ __html: truncatedHTML }} />;
  };
  return (
    <div
      className="clients-box d-flex flex-wrap justify-content-center width-100"
      id="clients"
    >
      <div className="container-inner">
        <h4
          className="c-89 DMSans-400 width-100"
          data-aos="fade-right"
          data-aos-duration="1500"
        >
          {" "}
          OUR CLIENTS
        </h4>
        <h3
          className="Arlon uppercase width-100"
          data-aos="fade-left"
          data-aos-duration="1500"
        >
          <span className="c-89">Fuelling success</span> for brands and
          enterprises
          <br /> across <span className="c-89">all sectors</span>
        </h3>
      </div>
      {loading ? (
        <LoadingComp statusLoading={setLoading} color="#b89848" />
      ) : (
        <div
          className="container-inner"
          data-aos="fade-up"
          data-aos-duration="1500"
        >
          <Slider {...clientBannerStudy} className="client-case-study">
            {clients.map((clientData) => (
              <div className="client-card d-flex flex-wrap" key={clientData.id}>
                <div className="client-inner relative d-flex align-items-end width-100">
                  <img
                    src={clientData.background}
                    className="img-client-bg img-cover width-100"
                    alt="img-client-bg"
                  />
                  <div className="client-content d-flex align-items-end relative width-100 z-2">
                    <div className="client-desc mb-40 ms-40">
                      <div className="d-flex flex-column text-start">
                        <div className="d-flex justify-content-start width-100">
                          <img
                            src={clientData.logo}
                            className="img-client-brand img-contain img-fluid"
                            alt="img-client-brand"
                          />
                        </div>
                        <h6 className="c-ff DMSans-700 s-4052">
                          {clientData.name}
                        </h6>

                        <TruncatedHTML
                          htmlString={clientData.shortDescription}
                          maxLength={160}
                        />
                        <Link
                          to={`/case-study/${clientData.id}`}
                          className="no c-ff DMSans-400 s-2026 mt-40"
                        >
                          View Case study &nbsp;{">"}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="client-desc-mobile width-100">
                  <div className="d-flex flex-column text-start">
                    <div className="d-flex justify-content-start width-100">
                      <img
                        src={clientData.logo}
                        className="img-client-brand img-contain img-fluid"
                        alt="img-client-brand"
                      />
                    </div>
                    <h6 className="c-00 DMSans-700">{clientData.name}</h6>

                    <TruncatedHTML
                      htmlString={clientData.shortDescription}
                      maxLength={160}
                    />
                    <Link
                      to={`/case-study/${clientData.id}`}
                      className="no c-00 DMSans-400 s-2026 mt-40"
                    >
                      View Case study &nbsp;{">"}
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      )}

      <div className="d-flex justify-content-center width-100">
        {loading ? (
          <LoadingComp statusLoading={setLoading} color="#b89848" />
        ) : (
          <div
            className="client-list width-100"
            data-aos="zoom-in"
            data-aos-duration="1300"
          >
            <Slider {...clientList}>
              {clientSlider.map((clientSlider) => (
                <div className="client-brand" key={clientSlider.id}>
                  <img
                    src={clientSlider.image}
                    className="img-fluid img-contain"
                    alt="client-list"
                  />
                </div>
              ))}
            </Slider>
          </div>
        )}
      </div>

      <div className="d-flex justify-content-center width-100">
        <div className="client-testimony">
          <h4
            className="c-00 DMSans-700 s-3647 title"
            data-aos="fade-left"
            data-aos-duration="1500"
          >
            What our Clients say
          </h4>
          {loading ? (
            <LoadingComp statusLoading={setLoading} color="#b89848" />
          ) : (
            <div className="width-100 my-50">
              <Slider {...clientSays}>
                {testimonies.map((item) => (
                  <div className="p-3" key={item.id}>
                    <div
                      className="card d-flex flex-column align-items-start"
                      data-aos="fade-right"
                      data-aos-duration="1500"
                    >
                      <div className="d-flex small-icon align-items-center">
                        <img
                          src={item.clientLogo}
                          alt={item.clientLogo}
                          className="img-fluid img-cover"
                        />
                        <p className="c-00 DMSans-700 client-title ms-3">
                          {item.clientName}
                        </p>
                      </div>
                      <h6 className="c-00 DMSans-700 sub-title">
                        <TruncatedHTML htmlString={item.title} maxLength={27} />
                      </h6>
                      <div className="testimony-desc text-start">
                        <TruncatedHTML
                          className="testimony-desc text-start"
                          htmlString={item.description}
                          maxLength={240}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
