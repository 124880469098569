import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import IconWa from "../../assets/icons/whatsapp-icon.png";
import { getLocation } from "../../services/api";
import ScrollToTop from "react-scroll-to-top";
export default function TogleWa() {
  const [location, setLocation] = useState([]);

  async function getLocationData() {
    try {
      const responseLocation = await getLocation(1).then(
        (result) => result.data.data
      );
      setLocation(responseLocation);
      setTimeout(() => {}, 3000);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    Aos.init();
    getLocationData();
  }, []);
  return (
    <div>
        <ScrollToTop smooth viewBox="0 0 18 24" width="18" height="24" svgPath="M0 10.1172L2.22989 12.5L9 5.26562L15.7701 12.5L18 10.1172L9 0.5L0 10.1172Z M0 21.3158L2.22989 23.5L9 16.8685L15.7701 23.5L18 21.3158L9 12.5L0 21.3158Z" fill="black"  />
      <div className="box-chat-wa pointer d-flex flex-row-reverse gap-3">
      
        <div className="box-wa">
          <Link to={`https://wa.me/${location.phone}`}>
            <img src={IconWa} className="icon-wa img-contain" alt="icon-wa" />
          </Link>
        </div>
        <div className="box-text">
          <div className="wa-text-outer">
            <div className="box-wa-text d-flex flex-column">
              <p className="DMSans-500 s-1418 c-ff">Contact us on</p>
              <h6 className="Arlon s-2026 c-ff">Whatsapp</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
