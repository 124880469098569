import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./styles/FontFamily/Fonts.css";
import "./styles/global.css";
import "./styles/custom.css";
import Homepage from "./pages/Homepage/Homepage";
import About from "./pages/About/About";
import Articles from "./pages/Articles/Articles";
import Erp from "./pages/ERP/ERP"
import CaseStudy from "./pages/CaseStudy/CaseStudy";
import SoftwareSolutionDetail from "./pages/SsDetail/SoftwareSolutionDetail";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/clients" element={<Homepage />} />
        <Route path="/office" element={<Homepage />} />
        <Route
          path="/software-solution/:id"
          element={<SoftwareSolutionDetail />}
        />
        <Route path="/about" element={<About />} />
        <Route path="/erp" element={<Erp />} />
        <Route path="/articles" element={<Articles />} />
        <Route path="/case-study/:id" element={<CaseStudy />} />
      </Routes>
    </Router>
  );
}

export default App;
