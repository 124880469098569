import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { getCareerByDivision, getDivision } from "../../services/api";
import LoadingComp from "../Loading/LoadingComp";

export default function Career() {
  const [divisions, setDivisions] = useState([]);
  const [selectedDivision, setSelectedDivision] = useState(1);
  const [jobList, setJobList] = useState([]);
  const [selectedJob, setSelectedJob] = useState(0);
  const [job, setJob] = useState([]);
  const [loading, setLoading] = useState(true);

  async function getDivisionData() {
    try {
      const responseDivision = await getDivision().then(
        (result) => result.data.data
      );
      setDivisions(responseDivision);
      setSelectedDivision(responseDivision[0].id);
      setTimeout(() => {
        setLoading(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  useEffect(() => {
    getDivisionData();
  }, []);

  useEffect(() => {
    async function getCareerData() {
      try {
        const responseCareer = await getCareerByDivision(selectedDivision).then(
          (result) => result.data.data
        );
        setJobList(responseCareer);
        setSelectedJob(responseCareer[0].id);
        setTimeout(() => {
          setLoading(false);
        }, 3000);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    getCareerData();
  }, [selectedDivision]);

  useEffect(() => {
    function getSelectedJobDescription() {
      try {
        const job = jobList.filter((jobDesk) => {
          return jobDesk.id === selectedJob;
        });
        setJob(job[0]);

        setTimeout(() => {
          setLoading(false);
        }, 3000);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
    getSelectedJobDescription();
  }, [selectedJob]);

  return (
    <div
      className="box-career d-flex justify-content-center width-100 mb-100"
      id="career"
    >
      <div className="container-inner">
        <div className="d-flex flex-column justify-content-center width-100">
          <div className="d-flex w-100 line bg-89" />
          <h3 className="Arlon c-00">CAREER</h3>
          <div className="width-100">
            <h5 className="DMSans-700 c-00 text-start">
              Available Positions
            </h5>
            {loading ? (
              <LoadingComp statusLoading={setLoading} color="#b89848" />
            ) : (
              <>
                <div className="career-division d-flex align-items-start w-100 text-start link">
                  {divisions.map((division) => (
                    <NavLink
                      className={`${
                        selectedDivision === division.id
                          ? "c-89 DMSans-700"
                          : "c-82 DMSans-400"
                      } text-nowrap no`}
                      key={division.id}
                      onClick={() => {
                        if (division.id !== selectedDivision) {
                          setSelectedDivision(division.id);
                        }
                      }}
                    >
                      {division.name}
                    </NavLink>
                  ))}
                </div>

                <div className="d-flex flex-wrap width-100">
                  <div className="career-position d-flex text-start">
                    {jobList.map((job) => (
                      <NavLink
                        className={`${
                          selectedJob === job.id ? "c-89 " : "c-82"
                        } text-nowrap no`}
                        key={job.id}
                        onClick={() => {
                          if (job.id !== selectedJob) {
                            setSelectedJob(job.id);
                          }
                        }}
                      >
                        {job.title}
                      </NavLink>
                    ))}
                  </div>
                  <div className="career-description d-flex flex-wrap justify-content-start">
                    <div className="description-point"
                      dangerouslySetInnerHTML={{
                        __html: job ? job.description : "",
                      }}
                    />
                    <button className="DMSans-700 c-89">
                      Apply Now {">"}
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
