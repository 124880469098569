import { React, useEffect } from "react";
import "../../styles/ERP.css";
import HeaderHomepage from "../../components/HeaderHomepage";
import Banner from "../../components/ERP/Banner";
import Content from "../../components/ERP/Content";
import DigitalWave from "../../components/Homepage/DigitalWave";
import Footer from "../../components/Footer";
import TogleWa from "../../components/TogleWa/TogleWa";

export default function ERP() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="erp">
      <TogleWa />
      <HeaderHomepage />
      <Banner />
      <Content />
      <DigitalWave />
      <Footer />
    </div>
  );
}
