import { React, useEffect, useState } from "react";
import Slider from "react-slick";
import Aos from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAbout, getAboutLeadership } from "../../services/api";
import LoadingComp from "../Loading/LoadingComp";

export default function Content() {
  const [loading, setLoading] = useState(true);
  const [about, setAbout] = useState([]);
  const [leadership, setLeadership] = useState([]);

  async function getAboutData() {
    try {
      const responseAbout = await getAbout().then((result) => result.data.data);
      setAbout(responseAbout);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }
  async function getLeadershipData() {
    try {
      const responseLeadership = await getAboutLeadership().then(
        (result) => result.data.data
      );
      setLeadership(responseLeadership);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  useEffect(() => {
    Aos.init();
    getLeadershipData();
    getAboutData();
  }, []);

  let sliderLeadership = {
    dots: false,
    arrows: false,
    infinite: true,
    centerMode: false,
    centerPadding: "0",
    autoplay: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    // prevArrow: <button><img src={left} alt='left-arrow' className='img-fluid' /></button> ,
    // nextArrow: <img src={right} alt='right-arrow' className='img-fluid'/>,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          centerMode: true,
          centerPadding: "40",
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerMode: true,
          centerPadding: "40",
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 568,
        settings: {
          centerMode: true,
          centerPadding: "30",
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <>
      {loading ? (
        <LoadingComp statusLoading={setLoading} color="#b89848" />
      ) : (
        <div className="d-flex flex-wrap justify-content-center width-100">
          <div className="container-story width-100">
            {about.map((aboutData) => (
              <div className="box-story width-100" key={aboutData.id}>
                <div className="d-flex flex-wrap justify-content-center">
                  <div
                    className="box-image relative width-100"
                    data-aos="fade-up-right"
                    data-aos-duration="1500"
                  >
                    <img
                      src={aboutData.image}
                      className="img-fluid img-cover width-100"
                      alt="img-cranium"
                    />
                  </div>

                  <div className="box-story-desc style-scrollbar">
                    <div className="cr-story-desc d-flex align-items-center width-100">
                      <div
                        className="story-desc-detail text-start"
                        data-aos="fade-up-right"
                        data-aos-duration="1500"
                        dangerouslySetInnerHTML={{
                          __html: aboutData.description,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="container-story width-100">
            <div className="box-experience d-flex flex-wrap justify-content-between width-100">
              <div
                className="experience-inner"
                data-aos="flip-right"
                data-aos-duration="1500"
              >
                <div className="card br-10 bg-89">
                  <h1 className="DMSans-700 c-ff">+50</h1>
                  <h3 className="Arlon c-ff">CLIENTS</h3>
                  <p className="DMSans-400 c-ff">
                    Lorem ipsum dolor sit amet consectetur. Ultrices hac
                    suspendisse duis viverra phasellus nunc.{" "}
                  </p>
                </div>
              </div>
              <div
                className="experience-inner"
                data-aos="flip-right"
                data-aos-duration="2000"
              >
                <div className="card br-10 bg-f0">
                  <h1 className="DMSans-700 c-89">+100</h1>
                  <h3 className="Arlon c-89">PROJECTS</h3>
                  <p className="DMSans-400 c-89">
                    Lorem ipsum dolor sit amet consectetur. Ultrices hac
                    suspendisse duis viverra phasellus nunc.{" "}
                  </p>
                </div>
              </div>
              <div
                className="experience-inner"
                data-aos="flip-right"
                data-aos-duration="2500"
              >
                <div className="card br-10 bg-ff">
                  <h1 className="DMSans-700 c-89">+100</h1>
                  <h3 className="Arlon c-89">EMPLOYEES</h3>
                  <p className="c-89 DMSans-400">
                    Lorem ipsum dolor sit amet consectetur. Ultrices hac
                    suspendisse duis viverra phasellus nunc.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="leadership content col-12 d-flex flex-column align-items-center justify-content-center">
            <h3
              className="c-00 Arlon s-4862 mb-100 col-10 text-start"
              data-aos="fade-up-right"
              data-aos-duration="1500"
            >
              OUR LEADERSHIP
            </h3>
            {loading ? (
              <LoadingComp statusLoading={setLoading} color="#b89848" />
            ) : (
              <div className="width-100">
                <Slider {...sliderLeadership}>
                  {[...leadership, ...leadership].map((item) => (
                    <div
                      data-aos="fade-up"
                      data-aos-duration="1500"
                      className="leadership-list relative width-100"
                      key={item.id}
                    >
                      <img
                        src={item.image}
                        className="leadership-img img-fluid img-cover width-100"
                        alt="leadership-img"
                      />
                      <div className="ld-position width-100">
                        <h4 className="width-100 DMSans-700 c-ff mb8">
                          {item.name}
                        </h4>
                        <p className="c-ff DMSans-400 s-2431">
                          {item.position}
                        </p>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}
